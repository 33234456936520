import React, { useState } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./auth/config";
import { ThemeProvider } from "@mui/material/styles";
import { useFetchToken } from "./auth/useFetchToken";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AppRoot from "./components/AppRoot";
import theme from "./config/theme";
import { client } from "./api/apollo";
import configureStore from "./store";
import AuthorizedContext from "./provider/AuthorizedContextProvider";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const store: any = configureStore();
const App: React.FC = () => {
  const { instance, inProgress } = useMsal();
  const { loading } = useFetchToken(instance, inProgress);
  const [authenticated, setAuthenticated] = useState(true);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <Router>
            <ThemeProvider theme={theme}>
              <ApolloProvider client={client}>
                <AuthorizedContext.Provider
                  value={{ authenticated, setAuthenticated }}
                >
                  <CssBaseline />
                  <ToastContainer />
                  {!loading && <AppRoot />}
                </AuthorizedContext.Provider>
              </ApolloProvider>
            </ThemeProvider>
          </Router>
        </Provider>
      </LocalizationProvider>
    </MsalAuthenticationTemplate>
  );
};

export default App;
