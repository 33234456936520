import { toast, ToastOptions } from "react-toastify";
import { GraphQLError } from "graphql";

interface ToastProps {
  message?: string | readonly GraphQLError[] | JSX.Element | undefined;
  type?: "success" | "error" | "warning" | "info" | undefined;
  id?: string | number;
  callback?: () => void;
  autoClose?: number;
}

function showToast({
  message,
  type,
  id,
  callback,
  autoClose = 2000,
}: ToastProps) {
  const toastOptions: ToastOptions = {
    position: "top-center",
    autoClose,
    toastId: id,
    onClose: callback,
  };

  switch (type) {
    case "success":
      toast.success(message || "Success!", toastOptions);
      break;
    case "error":
      toast.error(
        message || "There was an error, please try again",
        toastOptions
      );
      break;
    case "warning":
      toast.warning(message || "Warning!", toastOptions);
      break;
    default:
      toast.info(message || "Info!", toastOptions);
      break;
  }
}

export { showToast };
